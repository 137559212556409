<template>
	<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
</template>

<script>
export default {
	name: 'imofalform1-grid',
	data() {
		return {
			modelName: 'imofalform1',

			modelColumnDefs: {
				indarrivaldeparture: {
					render: (data) => {
						if (data === '1') {
							return this.$t('grid.imofalform1.isarrival');
						} else if (data === '2') {
							return this.$t('grid.imofalform1.isdeparture');
						} else {
							return '';
						}
					}
				}
			}
		};
	}
};
</script>
